<style>
  .c-box {
    background-color: black !important;
  }
  .c-leaderboard {
    background-color: #18191A !important; /* 404040 */
  }
  body {
    background-color: #478dd7 !important;
  }
  .landing-footer {
    background-color: #478dd7 !important;
  }
  .table-dark th, .table-dark td, .table-dark thead th {
    border-color: white;
  }
  .table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid white;
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid white;
  }
  .table-dark {
    background-color: #242526  !important;
    color: white !important;
    border-color: white !important;
  }
  .form-control {
    background-color: #242526  !important;
    color: white !important;
    border-color: white !important;
  }
</style>

<script>

export default {
  data() {
    return {
      ready: false,
      error: false,
      available: false,
      loading: false,
      servers: [],
      server: null,
      stat: 'kdratio',
      stats: ['kills', 'deaths', 'kdratio', 'playtime', 'longest_kill'],
      leaderboard: [],
      player: {},
      playerLoading: null,
      playerError: false,
      steam64: null,
      activePage: 'leaderboard',
      rootWindow: true
    };
  },
  created() {
    window.addEventListener("scroll", this.windowScroll);
    this.requestStats();
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  mounted() {
    this.rootWindow = (window.top === window.self);
  },
  methods: {
    humanizeStat(stat) {
      switch(stat) {
        case 'kills': return 'Kills';
        case 'deaths': return 'Deaths';
        case 'kdratio': return 'K/D Ratio';
        case 'playtime': return 'Playtime (minutes)';
        case 'longest_kill': return 'Longest Kill (meters)';
      }
    },
    selectServer(server) {
      this.server = server;
      if(this.activePage === 'leaderboard') this.requestLeaderboard(false);
      else if(this.activePage === 'personal') this.requestSteam64();
    },
    requestServers: function() {
      fetch('https://api-portal-n3phxat1my.cfapi.de/servers').then(response => {
        return response.json();
      }).then(data => {
        this.servers = data.servers;
        this.selectServer(this.servers[0]);
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.error = true;
        // eslint-disable-next-line no-console
        console.log(`[ERROR] ${error}`);
      })
    },
    requestStats: function() {
      fetch('https://api-portal-n3phxat1my.cfapi.de/status').then(response => {
        return response.json();
      }).then(data => {
        this.available = data.available;
        this.ready = true;
        this.requestServers();
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.error = true;
        this.ready = true;
        // eslint-disable-next-line no-console
        console.log(`[ERROR] ${error}`);
      })
    },
    requestLeaderboard: function(resetReadyState) {
      if(resetReadyState === true) this.ready = false;

      fetch(`https://api-portal-n3phxat1my.cfapi.de/leaderboard?identifier=${this.server}&stat=${this.stat}`).then(response => {
        return response.json();
      }).then(data => {
        this.leaderboard = data.leaderboard;
        this.ready = true;
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.error = true;
        this.ready = true;
        // eslint-disable-next-line no-console
        console.log(`[ERROR] ${error}`);
      })
    },
    requestSteam64() {
      this.player = null;
      this.playerError = false;
      this.playerLoading = true;

      if(!this.steam64) return;
      fetch(`https://api-portal-n3phxat1my.cfapi.de/player?identifier=${this.server}&steam64=${this.steam64}`).then(response => {
        return response.json();
      }).then(data => {
        this.player = data;
        this.playerError = false;
        this.playerLoading = false;
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.playerError = true;
        this.playerLoading = false;
        // eslint-disable-next-line no-console
        console.log(`[ERROR] ${error}`);
      })
    },
    /**
     * Window scroll method
     */
    windowScroll() {
      const navbar = document.getElementById("navbar");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      document.getElementById("topnav-menu-content").classList.toggle("show");
    },
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    },
    changeView(view) {
      this.activePage = view;
      if(view === 'leaderboard') this.requestLeaderboard();
      else if(view === 'personal') {
        this.steam64 = null;
        this.player = null;
        this.playerError = false;
        this.playerLoading = false;
      }
    },
    join(t, a, s) {
      function format(m) {
        let f = new Intl.DateTimeFormat('en', m);
        return f.format(t);
      }
      return a.map(format).join(s);
    }
  }
};
</script>

<template>
  <div>
    <nav class="navbar navbar-expand-lg navigation fixed-top sticky c-box" id="navbar" v-if="rootWindow">
      <div class="container">
        <a class="navbar-logo" href="/">
          <img src="@/assets/images/brand.png" alt height="70" class="logo logo-light" />
        </a>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
          data-toggle="collapse"
          data-target="#topnav-menu-content"
          @click="toggleMenu()"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul
            class="navbar-nav ml-auto"
            id="topnav-menu"
            v-scroll-spy-active="{ selector: 'a.nav-link' }"
          >

          </ul>
          <div class="ml-lg-2">
            <a href="https://discord.com/invite/lonesurvivor" class="btn btn-primary btn-lg" style="border-radius: 0;">
              <h4 class="mb-0"><i class="fab fa-discord mr-1"></i> <b>Join Discord</b></h4>
            </a>
          </div>
        </div>
      </div>
    </nav>
    <div v-scroll-spy>
      <!-- Supported games -->
      <template v-if="!ready">
        <section class="section c-leaderboard">
          <div class="container">
            <div class="row mt-4">
              <div class="col-lg-12 col-sm-12 text-center">
                <h4>Loading leaderboard data...</h4>
                <img src="@/assets/images/statloader.svg" height="100px" class="mt-4"/>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-else-if="error">
        <section class="section c-leaderboard">
          <div class="container">
            <div class="row mt-4">
              <div class="col-lg-12 col-sm-12 text-center" style="min-height: 100px !important;">
                <h4 class="text-danger">
                  Oh snap, something broke on our end. Please hang tight and try again later.
                </h4>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-else-if="!available">
        <section class="section c-leaderboard">
          <div class="container">
            <div class="row mt-4">
              <div class="col-lg-12 col-sm-12 text-center" style="min-height: 100px !important;">
                <h4 class="text-warning">
                  We are currently re-loading the leaderboard data, please refresh the page.
                </h4>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <section class="section c-leaderboard">
          <div class="container">
            <div class="row justify-content-center mt-4">
              <div class="col-lg-12">
                <div class="text-center mb-5">
                  <div class="small-title">
                    <button class="btn btn-primary btn-xs btn-sm" :class="{'btn-success': activePage === 'leaderboard', 'disabled': activePage === 'leaderboard'}" :disabled="activePage === 'leaderboard'" v-on:click="changeView('leaderboard')">
                      Global Leaderboard
                    </button>

                    or

                    <button class="btn btn-primary btn-xs btn-sm" :class="{'btn-success': activePage === 'personal', 'disabled': activePage === 'personal'}" :disabled="activePage === 'personal'" v-on:click="changeView('personal')">
                      Personal stats
                    </button>
                  </div>
                  <h4>{{ server }} <template v-if="activePage === 'leaderboard'">- {{ humanizeStat(stat) }}</template></h4>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="col-lg-12" v-if="activePage === 'leaderboard'">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="float-left">
                    <select class="form-control" v-model="server" v-on:change="requestLeaderboard(true);">
                      <option :value="server" v-for="server in servers" :key="server">
                        {{ server }}
                      </option>
                    </select>
                  </div>
                  <div class="float-right">
                    <select class="form-control" v-model="stat" v-on:change="requestLeaderboard(true);">
                      <option :value="stat" v-for="stat in stats" :key="stat">
                        {{ humanizeStat(stat) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center mt-4">
                <div class="col">
                  <div class="table-responsive">
                    <table class="table table-dark mb-0">
                      <thead>
                      <template v-if="stat === 'kdratio'">
                        <tr>
                          <th>Rank</th>
                          <th>Playername</th>
                          <th>Kills</th>
                          <th>Deaths</th>
                          <th>KD</th>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <th>Rank</th>
                          <th>Playername</th>
                          <th>{{ humanizeStat(stat) }}</th>
                        </tr>
                      </template>

                      </thead>
                      <tbody>
                      <template v-if="stat === 'kdratio'">
                        <tr v-for="player in leaderboard" :key="player.rank">
                          <th scope="row">{{player.rank}}</th>
                          <td>{{player.latest_name}}</td>
                          <td>{{ player.kills }}</td>
                          <td>{{ player.deaths }}</td>
                          <td>{{player.kdratio}}</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr v-for="player in leaderboard" :key="player.rank">
                          <th scope="row">{{player.rank}}</th>
                          <td>{{player.latest_name}}</td>
                          <td>
                            <template v-if="stat === 'playtime'">
                              {{ Math.round(player[stat] / 60) }}
                            </template>
                            <template v-else>
                              {{ player[stat] }}
                            </template>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12" v-else-if="activePage === 'personal'">
              <div class="row">
                <div class="col-lg-3 col-sm-12">
                  <select class="form-control" v-model="server" v-on:change="requestSteam64();">
                    <option :value="server" v-for="server in servers" :key="server">
                      {{ server }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-9 col-sm-12">
                  <b-input v-model="steam64" placeholder="Enter your Steam64" v-on:change="requestSteam64()"></b-input>
                </div>
              </div>
              <div class="row mt-4" v-if="playerLoading">
                <div class="col-lg-12 col-sm-12 text-center">
                  <h4>Loading player stats...</h4>
                  <img src="@/assets/images/statloader.svg" height="100px" class="mt-4"/>
                </div>
              </div>
              <div class="row mt-4" v-else-if="playerError">
                <div class="col-lg-12 col-sm-12 text-center" style="min-height: 100px !important;">
                  <h4 class="text-danger">
                    Could not get player stats, make sure the Steam64 is entered correctly
                  </h4>
                </div>
              </div>
              <div class="row mt-4" v-else-if="player && player.status === false || player && !player.player.game || player && !player.player.game.general">
                <div class="col-lg-12 col-sm-12 text-center" style="min-height: 100px !important;">
                  <h4 class="text-danger">
                    No stats for this player
                  </h4>
                </div>
              </div>
              <div class="mt-4" v-else-if="player">
                <h4 class="pb-0 text-center">
                  {{player.player.omega.name_history[0]}}
                  <small v-if="player.player.cleared_at">
                    Last reset: {{join(parseDate(player.player.cleared_at), [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}], ' ')}}
                  </small>
                  <small v-else>
                    Last reset: {{join(parseDate(player.player.created_at), [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}], ' ')}}
                  </small>
                </h4>
                <div class="row justify-content-center mt-4">
                  <div class="col-lg-6">
                    <div class="table-responsive mb-0">
                      <table class="table table-dark table-nowrap table-centered">
                        <tbody class="w-100">
                          <tr>
                            <td>
                              <b class="text-uppercase text-muted small">
                                K/D ratio
                              </b>
                              <b> {{ player.player.game.general.kdratio }} </b>
                              <span class="ml-1"> ({{ player.player.game.general.kills }} / {{ player.player.game.general.deaths }}) </span>
                            </td>
                            <td>
                              <b class="text-uppercase text-muted small">
                                Playtime
                              </b>
                              <b>
                                  {{ Math.round(player.player.omega.playtime/60) }} minutes
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b class="text-uppercase text-muted small">
                                Kills
                              </b>
                              <b> {{ player.player.game.general.kills }} </b>
                            </td>
                            <td>
                              <b class="text-uppercase text-muted small">
                                Deaths
                              </b>
                              <b>
                                {{ player.player.game.general.deaths }}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b class="text-uppercase text-muted small">
                                Longest Shot
                              </b>
                              <b> {{ player.player.game.general.longest_shot }}m </b>
                            </td>
                            <td>
                              <b class="text-uppercase text-muted small">
                                Longest Kill
                              </b>
                              <b> {{ player.player.game.general.longest_kill }}m </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
          <!-- end container -->
        </section>
      </template>

      <!-- Footer start -->
      <footer class="landing-footer" v-if="rootWindow">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4 mb-lg-0">
                <h5 class="mb-3 footer-list-title">Servers</h5>
                <ul class="list-unstyled footer-list-menu">
                  <li>
                    <a href="https://lone-survivor.com/server-status">Server Status</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4 mb-lg-0">
                <h5 class="mb-3 footer-list-title">Resources</h5>
                <ul class="list-unstyled footer-list-menu">
                  <li>
                    <a href="https://lone-survivor.com/privacy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4 mb-lg-0">
                <h5 class="mb-3 footer-list-title">Links</h5>
                <ul class="list-unstyled footer-list-menu">
                  <li>
                    <a href="https://lone-survivor.com">Homepage</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- end row -->

          <hr class="footer-border my-5" />

          <div class="row">
            <div class="col-lg-8">
              <div class="mb-4">
                <img src="@/assets/images/brand.png" alt height="100" class="logo logo-light" />
              </div>

              <p
                class="mb-2"
              >{{ new Date().getFullYear() }} © Lone Survivor DayZ. All rights reserved.</p>
            </div>
          </div>
        </div>
        <!-- end container -->
      </footer>
      <!-- Footer end -->
    </div>
  </div>
</template>